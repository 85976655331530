<template>
  <v-container>
    <v-card class="mx-auto" :loading="loading" outlined>
      <v-card-title>Solicitação:</v-card-title>
      <v-card-text>
        <v-list-item v-for="(item, i) in fields" :key="i">
          <v-list-item-content>
            <v-list-item-title>
              <strong>{{ item.label }}: </strong>
              <span v-if="item.value !== ''" v-html="item.value"></span>
              <ApprovalPlansMultipleComponent
                v-if="item.type === 'plans'"
                :field="item"
              />
              <ApprovalPackagesMultipleComponent
                v-if="item.type === 'busca_pacote'"
                :field="item"
                :page="page"
              />
              <ApprovalChangeDDDComponent
                v-if="item.type === 'troca_ddd' || item.type === 'change_number_undue_divulgation'"
                :field="item"
                :page="page"
                :titlePage="item.display_name"
              />
              <PackagesMultipleComponent
                v-if="item.type === 'roaming'"
                :field="item"
                :page="page"
              />
              <LineDisablePackage
                v-if="item.type === 'line_disable_package'"
                :field="item"
                :page="page"
              />
              <CancelLineComponent
                v-if="item.type === 'cancel_line'"
                :field="item"
                :page="page"
              />
              <SuspendLineComponent
                v-if="item.type === 'suspend_line'"
                :field="item"
                :page="page"
              />
              <ChangeChipComponent
                v-if="item.type === 'change_chip'"
                :field="item"
                :page="page"
              />
              <ReactivateLineComponent
                v-if="item.type === 'reactivate_line'"
                :field="item"
                :page="page"
              />
              <VoluntaryLineSuspensionComponent
                v-if="item.type === 'voluntary_line_suspension' || item.type === 'suspensao_perda_roubo'"
                :field="item"
                :page="page"
              />
              <OnlineManagerComponent
                v-if="item.type === 'update_gol'"
                :field="item"
                :page="page"
              />
              <ActivateNewLineComponent
                v-if="item.type === 'activate_new_line'"
                :field="item"
                :page="page"
              />
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-card-text>
      <v-card-actions>
        <v-row cols="12" class="d-flex flex-row-reverse">
          <v-col cols="3">
            <v-btn
                block
                color="primary"
                @click="optionSelected(true)"
                :disabled="loading"
            >Aprovar</v-btn
            >
          </v-col>
          <v-col cols="3">
            <v-btn
                block
                color="error"
                @click="optionSelected(false)"
                :disabled="loading"
            >Reprovar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
    <v-dialog
      v-model="justifyDialog"
      class="mx-auto"
      persistent
      max-width="70%"
    >
      <v-card outlined>
        <v-card-title>Digite a justificativa</v-card-title>
        <v-card-text>
          <v-textarea
            clearable
            autofocus
            v-model="justify"
            outlined
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="blue-grey darken-2"
            text
            @click="justifyDialog = false"
            :disabled="loading"
          >
            Fechar
          </v-btn>
          <v-btn
            color="primary"
            @click="approve(false)"
            text
            :loading="loading"
            :disabled="loading"
          >
            Salvar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import ApprovalPlansMultipleComponent from "@/components/approval/approval_forms/ApprovalPlansMultipleComponent";
import ApprovalPackagesMultipleComponent from "@/components/approval/approval_forms/ApprovalPackagesMultipleComponent";
import ApprovalChangeDDDComponent from "@/components/approval/approval_forms/ApprovalChangeDDDComponent";
import PackagesMultipleComponent from "@/components/customFieldsComponents/PackagesMultipleComponent";
import LineDisablePackage from "@/components/customFieldsComponents/LineDisablePackageComponent";
import CancelLineComponent from "@/components/customFieldsComponents/CancelLineComponent";
import SuspendLineComponent from "@/components/customFieldsComponents/SuspendLineComponent";
import ChangeChipComponent from "@/components/customFieldsComponents/ChangeChipComponent";
import ReactivateLineComponent from "@/components/customFieldsComponents/ReactivateLineComponent";
import VoluntaryLineSuspensionComponent from "@/components/customFieldsComponents/VoluntaryLineSuspensionComponent";
import OnlineManagerComponent from "@/components/customFieldsComponents/OnlineManagerComponent";
import ActivateNewLineComponent from "@/components/customFieldsComponents/ActivateNewLineComponent";

import { mapActions, mapGetters, mapState } from "vuex";

export default {
  components: {
    ApprovalPlansMultipleComponent,
    ApprovalPackagesMultipleComponent,
    ApprovalChangeDDDComponent,
    PackagesMultipleComponent,
    LineDisablePackage,
    CancelLineComponent,
    SuspendLineComponent,
    ChangeChipComponent,
    ReactivateLineComponent,
    VoluntaryLineSuspensionComponent,
    OnlineManagerComponent,
    ActivateNewLineComponent
  },
  data() {
    return {
      loading: true,
      page: "approval",
      fields: [],
      justifyDialog: false,
      justify: ""
    };
  },
  async mounted() {
    try {
      await Promise.all([
        this.loadUsers(),
        this.loadCompanies()
      ]);
    } catch (error) {
      await this.$swal("Oops...", error.message, "error");
    }

    this.fields = this.getSelectedSolicitationValues;
    this.loading = false;
  },
  computed: {
    ...mapGetters("solicitation", ["getSelectedSolicitationValues","getChangeChipComponentValue"]),
    ...mapState("user", ["users"]),
    ...mapState("company", ["companies"])
  },
  methods: {
    ...mapActions("solicitation", ["approveSolicitation"]),
    ...mapActions("user", ["loadUsers"]),
    ...mapActions("company", ["loadCompanies"]),
    optionSelected(option) {
      if (!option) {
        this.justifyDialog = true;
      } else {
        this.approve(true);
      }
    },
    async approve(approved) {
      this.loading = true;
      try {
        const retorno = await this.approveSolicitation({
          id_solicitation: this.$route.params.solicitation_id,
          approved: approved,
          justification: this.justify,
          fields: this.getChangeChipComponentValue
        });
        if (retorno.success === true) {
          this.$swal(retorno.message, "", "success");
          await this.$router.push({
            name: "solicitation"
          });
        }
      } catch (error) {
        this.$swal("Oops...", error.message, "error");
      }
      this.loading = false;
    }
  }
};
</script>
<style scoped></style>
