<template>
  <v-row class="mx-auto">
    <v-col v-for="(dddChange, index) in field.data" :key="index" md="6" sm="12">
      <v-card class="mx-auto" outlined>
        <v-card-title class="primary white--text" :title="titlePage">
          <div class="headerClass">
             {{ dddChange.line.nr_linha ? dddChange.line.ddi + ' (' +dddChange.line.ddd + ') ' + dddChange.line.nr_linha : 'Selecione uma linha' }}
          </div>
        </v-card-title>
        <v-card-text class="mb-n9">
          <v-row>
            <v-col cols="12">
              <div v-if="loading">
                <v-skeleton-loader type="heading"></v-skeleton-loader>
              </div>
              <!-- Linha -->
              <v-autocomplete
                  v-model="dddChange.line"
                  :items="[dddChange.line]"
                  item-text="nr_linha"
                  item-value="id_linha"
                  outlined
                  dense
                  label="Linha"
                  v-if="!loading && dddChange.line"
                  readonly
              >
                <template v-slot:selection="data">
                  {{ data.item.ddi }} ({{ data.item.ddd }})
                  {{ data.item.nr_linha }} - {{ data.item.nome_completo }}
                </template>
                <template v-slot:item="data">
                  <template>
                    <v-list-item-content>
                      <v-list-item-title>
                        {{ data.item.ddi }} ({{ data.item.ddd }})
                        {{ data.item.nr_linha }}
                      </v-list-item-title>
                      <v-list-item-subtitle
                          v-html="data.item.nome_completo"
                      ></v-list-item-subtitle>
                    </v-list-item-content>
                  </template>
                </template>
              </v-autocomplete>
              <!-- DDD -->
              <v-autocomplete
                  dense
                  outlined
                  label="DDD"
                  v-model="dddChange.ddd"
                  :items="[dddChange.ddd]"
                  item-text="code"
                  item-value="code"
                  readonly
                  v-if="!loading && dddChange.ddd"
              >
                <template v-slot:selection="data">
                  {{ data.item.code }} - {{ data.item.region }}
                </template>
              </v-autocomplete>
              <!-- ICCID -->
              <v-text-field
                  label="ICCID"
                  v-model="dddChange.iccid.iccid"
                  outlined
                  dense
                  v-if="!loading && dddChange.iccid && isChangeDDD"
                  readonly
              />
              <!-- Conta -->
              <v-autocomplete
                  v-model="dddChange.conta"
                  :items="[dddChange.conta]"
                  item-text="cod_conta"
                  item-value="value"
                  outlined
                  dense
                  label="Conta"
                  v-if="!loading && dddChange.conta && isChangeDDD"
                  readonly
              >
                <template v-slot:selection="data">
                  <template>
                    (DDD - {{ data.item.ddd }}) - {{ data.item.cod_conta }} - {{ data.item.nome_operadora }}
                  </template>
                </template>
              </v-autocomplete>
              <LineReference
                v-if="!loading && isChangeDDD"
                :selectedLine="dddChange.linha_referencia"
                :readonly="true"
              ></LineReference>
              <v-checkbox
                  :label="'Deseja vincular a uma nova pessoa?'"
                  v-model="dddChange.person.checkbox"
                  class="mt-0"
                  dense
                  v-if="!loading && dddChange.person.checkbox && isChangeDDD"
                  readonly
              ></v-checkbox>
              <!-- Nova Pessoa -->
              <v-autocomplete
                  dense
                  outlined
                  :rules="!!dddChange.person.required ? required : []"
                  label="Nova Pessoa"
                  v-model="dddChange.person"
                  :items="[dddChange.person]"
                  item-text="nome_completo"
                  item-value="id_pessoa"
                  readonly
                  v-if="!loading && dddChange.person && dddChange.person.checkbox && isChangeDDD"
              >
              </v-autocomplete>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- Pacotes da linha -->
        <v-card-text class="align-start">
          <v-alert
            border="left"
            class="text-center"
            dense
            type="warning"
            v-if="loading"
            dark
          >Carregando pacotes da linha!
          </v-alert>
          <template v-if="dddChange.packages.data.length && !loading">
            <v-alert border="left" color="indigo" dark dense class="mb-0">
              Pacotes da linha:
            </v-alert>
            <v-list>
              <template v-for="(linePackage, index) in dddChange.packages.data">
                <v-list-item :key="linePackage.nome">
                  <v-list-item-content>
                    <v-list-item-title>
                      {{ linePackage.nome }}
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>

                <v-divider
                  v-if="index < dddChange.packages.data.length - 1"
                  :key="index"
                ></v-divider>
              </template>

              <template v-if="dddChange.packages.data == ''">
                <v-list-item>
                  <v-list-item-content>
                    <v-list-item-title>
                      Não possui
                    </v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </template>
            </v-list>
          </template>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState } from "vuex";
import LineReference from "@/components/fields/LineReference.vue";
export default {
  components: { LineReference },
  props: ["field", "page", "titlePage"],
  data() {
    return {
      isChangeDDD: false,
      loading: true
    };
  },
  async mounted() {
    let params = {};
    if (this.page === 'approval') {
      params = { showAll: true }
    }

    await this.loadLines(params).catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    await this.loadPersons().catch(error => {
      this.$swal("Oops...", error.message, "error");
    });
    this.isChangeDDD = this.field.type === 'troca_ddd' ?? true;
    this.loading = false;
  },
  computed: {
    ...mapState("packages", ["lines"]),
    ...mapState("persons", ["persons"])
  },
  methods: {
    ...mapActions("packages", ["loadLines"]),
    ...mapActions("persons", ["loadPersons"])
  }
};
</script>
<style scoped>
.v-card__text,
.v-card__title {
  word-break: normal; /* maybe !important  */
}
</style>
