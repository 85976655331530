<template>
  <v-row class="mx-auto">
    <v-col cols="12" v-if="page !== 'interaction' && page !== 'approval'">
      <v-card outlined :loading="loading" :disabled="loading">
        <v-card-title class="subtitle-1"></v-card-title>
        <v-card-text>
          <v-btn color="primary" @click="clickAddNewLine()"
            >Selecionar Linha</v-btn
          >
        </v-card-text>
      </v-card>
    </v-col>
    <v-col
      v-for="(lineSelected, lineIndex) in lineSelect"
      :key="lineIndex"
      :cols="lineSelect.length > 1 ? 6 : 12"
    >
      <v-card outlined class="mx-auto">
        <v-card-title
          class="primary white--text"
          v-if="page !== 'interaction'"
        >
          <div class="headerClass">
            {{ `${lineSelected.line.ddi} (${lineSelected.line.ddd}) ${lineSelected.line.nr_linha}` }}
          </div>
          <v-spacer></v-spacer>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="12">
              <LineComponent
                :selectedLine="lineSelected.line"
                :readonly="true"
              >
              </LineComponent>
              <v-text-field
                label="ICCID novo SIM Card"
                outlined
                dense
                v-model="lineSelected.iccid.iccid"
                v-if="page === 'approval'"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from "vuex";
import LineComponent from "@/components/forms/LineComponent.vue";
export default {
  components: { LineComponent },
  props: ["field", "page"],
  data() {
    return {
      lineSelect: [],
      loading: true,
    };
  },
  async mounted() {
    this.lineSelect = this.field.values;
    let params = {};
    if (this.page === 'approval') {
      params = { showAll: true }
    }

    this.loading = false;
  },
  computed: {
    ...mapState("solicitation", ["selected_solicitation_validate_fields"]),

    ...mapState("packages", ["lines"]),
    ...mapGetters({
      linePackages: "packages/getLinePackages"
    })
  },
  methods: {
    ...mapActions("packages", ["loadLines", "loadLinePackages"]),
    ...mapActions("solicitation", [
      "setChangeChipComponentValues",
      "updateSolicitationValidateChangeChip"
    ]),
    ...mapMutations({ setModel: "forms/setModelCancelLine" }),

  }
};
</script>
<style scoped>
.headerClass {
  white-space: nowrap;
  word-break: normal;
  overflow: hidden;
  text-overflow: ellipsis;
}
</style>
